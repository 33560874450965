import React from 'react';
import { Button, Icon } from '@nimbus-ds/components';
import { useNavigate } from 'react-router-dom';
import { CogIcon } from '@nimbus-ds/icons';
import { t } from 'i18next';

const ConfigButton: React.FC = () => {
  const navigate = useNavigate();
  const handleGoToConfigs = () => navigate('/configs');

  return (
    <Button onClick={handleGoToConfigs}>
      <Icon source={<CogIcon />} color="currentColor" />
      {t('general.config')}
    </Button>
  );
};

export default ConfigButton;
