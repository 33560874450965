import { createRoot } from 'react-dom/client'; // Import correto para React 18
import App from './App';
import './index.css';

import '@nimbus-ds/styles/dist/index.css';
import '@nimbus-ds/styles/dist/themes/dark.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './lib/i18n/i18n';
import reportWebVitals from './reportWebVitals';

// Obtenha o container do DOM onde o app será renderizado
const container = document.getElementById('root');
if (!container) {
  throw new Error("Elemento 'root' não encontrado no DOM.");
}

reportWebVitals((metric) => {
  console.log('Web Vitals metric:', metric);
});

// Crie a instância do root e renderize o app
const root = createRoot(container);

root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
);
