// Interface para as opções de field_type
export enum KitDisplayModeOptions {
  DEFAULT = 'default',
  BLOCKS = 'blocks',
}

export enum BuyTogetherDisplayModeOptions {
  GRID = 'grid',
  LIST = 'list',
  MINIMAL = 'minimal',
}

export enum VariationsModeOptions {
  SELECT = 'select',
  BUTTONS = 'buttons',
}

export enum MethodOptions {
  APPEND = 'append',
  PREPEND = 'prepend',
  IN = 'in',
  AFTER = 'after',
  BEFORE = 'before',
}

export interface IStoreConfig {
  _id?: string;
  store_id?: number;
  buy_together_display_mode: BuyTogetherDisplayModeOptions;
  variations_mode: VariationsModeOptions;
  buy_together_desktop_position: string;
  buy_together_desktop_position_method: MethodOptions;
  buy_together_mobile_position: string;
  buy_together_mobile_position_method: MethodOptions;
  buy_together_title: string;
  buy_together_message_discount: string;
  buy_together_message_no_discount: string;
  buy_together_cta_text: string;
  buy_together_disabled_option_text: string;
  buy_together_enabled_option_text: string;
  success_color: string;
  primary_color: string;
  secondary_color: string;
  title_color: string;
  text_color: string;
  border_color: string;
  options_bg_color: string;
  options_border_color: string;
  options_text_color: string;
  custom_colors: boolean;
  show_image_in_kit?: boolean;
  kit_block_buy_not_stock?: boolean;
  kit_display_mode: KitDisplayModeOptions;
  kit_anchor_element_price_de: string;
  kit_anchor_element_price_por: string;
  kit_anchor_element_variants: string;
  kit_anchor_element_action: string;
  kit_anchor_showcase_item: string;
  kit_anchor_showcase_item_cta: string;
  is_active: boolean;
  createdAt?: string;
  updatedAt?: string;
  block_products_active: boolean;
}

// Valor padrão
export const defaultStoreConfig: IStoreConfig = {
  buy_together_display_mode: BuyTogetherDisplayModeOptions.GRID,
  variations_mode: VariationsModeOptions.BUTTONS,
  buy_together_desktop_position: '[data-store*="product-info-"]',
  buy_together_desktop_position_method: MethodOptions.AFTER,
  buy_together_mobile_position: '[data-store*="product-info-"]',
  buy_together_mobile_position_method: MethodOptions.AFTER,
  buy_together_title: 'Compre Junto',
  buy_together_message_discount: 'Leve {qtd} itens e economize {valor}',
  buy_together_message_no_discount: 'Leve {qtd} produtos juntos',
  buy_together_cta_text: 'Adicionar tudo',
  buy_together_disabled_option_text: 'Não quero',
  buy_together_enabled_option_text: 'Quero',
  success_color: '#25d058',
  primary_color: '#358ebb',
  secondary_color: '#363636',
  title_color: '#383838',
  text_color: '#404040',
  border_color: '#f5f5f5',
  options_bg_color: '#ffffff',
  options_border_color: '#000000',
  options_text_color: '#000000',
  custom_colors: true,
  show_image_in_kit: false,
  kit_block_buy_not_stock: false,
  kit_display_mode: KitDisplayModeOptions.DEFAULT,
  kit_anchor_element_price_de: '',
  kit_anchor_element_price_por: '',
  kit_anchor_element_variants: '',
  kit_anchor_element_action: '',
  kit_anchor_showcase_item: '',
  kit_anchor_showcase_item_cta: '',
  is_active: true,
  block_products_active: false,
};
