import { useEffect } from 'react';

import { Box, Button, Icon, Link } from '@nimbus-ds/components';
import { HelpLink, Layout, Page } from '@nimbus-ds/patterns';
import { navigateHeaderRemove } from '@tiendanube/nexo';

import nexo from '../../nexoClient';
import { useTranslation } from 'react-i18next';
import { AppStatusBar } from '../../components';
import GroupsListTable from './GroupsListTable';
import { ExternalLinkIcon, PlusCircleIcon } from '@nimbus-ds/icons';
import { useNavigate } from 'react-router-dom';
import apiInstance from '../../utils/apiUtils';

function PageMain() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSyncTags = async () => {
    try {
      await apiInstance.post(`/groups/update-tags-kits`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  return (
    <>
      <Page>
        <Page.Header
          title={t('MainPage.title')}
          buttonStack={
            <Box display="flex" gap="2">
              <Button appearance="primary" onClick={() => navigate('/group')}>
                <Icon color="currentColor" source={<PlusCircleIcon />} />
                {t('MainPage.GroupsListTable.cta_add')}
              </Button>
            </Box>
          }
        />
        <Page.Body>
          <Box display="none">
            <Button onClick={handleSyncTags}>Sincronizar tags</Button>
          </Box>
          <Layout columns="1">
            <Layout.Section>
              <AppStatusBar />
            </Layout.Section>
            <Layout.Section>
              <Box display="grid" gap="10">
                <GroupsListTable />
              </Box>
            </Layout.Section>
            <Layout.Section>
              <HelpLink>
                <Link
                  as="a"
                  href="https://huapps.com.br/ajuda/compre-junto-pro/pt"
                  target="_blank"
                  appearance="primary"
                  textDecoration="none"
                >
                  Mais informações sobre Compre Junto Pro
                  <Icon source={<ExternalLinkIcon />} color="currentColor" />
                </Link>
              </HelpLink>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
}

export default PageMain;
