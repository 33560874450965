import { Button, Text } from '@nimbus-ds/components';
import { Layout, Page } from '@nimbus-ds/patterns';
import nexo from '../../nexoClient';
import { goTo } from '@tiendanube/nexo';

function UnauthorizedPage() {
  const handleGoInstall = () => {
    goTo(nexo, `/apps/${process.env.REACT_APP_NS_APP_ID}/authorize`);
  };

  return (
    <>
      <Page maxWidth="800px">
        <Page.Header title="Acesso Restrito" />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Text>
                Esta página é de acesso restrito. Por favor, instale o
                aplicativo para continuar.
              </Text>
              <Button onClick={handleGoInstall}>Instalar</Button>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
}

export default UnauthorizedPage;
