import React, { ChangeEvent, useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import {
  Box,
  Button,
  Card,
  Icon,
  IconButton,
  Input,
  Link,
  Pagination,
  Sidebar,
  Text,
  Thumbnail,
  Title,
  Toggle,
  useToast,
} from '@nimbus-ds/components';
import {
  PlusCircleIcon,
  SearchIcon,
  TrashIcon,
  UndoIcon,
} from '@nimbus-ds/icons';
import {
  DataList,
  EmptyMessage,
  InteractiveList,
  Layout,
  Page,
} from '@nimbus-ds/patterns';
import { navigateHeader } from '@tiendanube/nexo';
import { useTranslation } from 'react-i18next';
import apiInstance from '../../utils/apiUtils';
import { IPaginateMeta } from '../../lib/interfaces/paginate.interfaces';
import { IBlockProduct } from '../../lib/interfaces/blockProducts.interfaces';
import { IStoreConfig } from '../../lib/interfaces/config.interfaces';
import { formatDate } from '../../utils/formatUtils';

const BlockProductsPage: React.FC = () => {
  const { t } = useTranslation();

  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(true);
  const [blockProducts, setBlockProducts] = useState<IBlockProduct[]>([]);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(true);
  const [openSearchProdut, setOpenSearchProdut] = useState<boolean>(false);
  const [products, setProducts] = useState<any[]>([]);
  const [productsMeta, setProductsMeta] = useState<IPaginateMeta>();
  const [searchProductQuery, setSearchProductQuery] = useState<string>('');
  const [blockStatus, setBlockStatus] = useState<boolean>(false);

  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });
    getConfig();
    getBlockProductsList();
  }, []);

  const getConfig = async () => {
    setLoading(true);
    try {
      const result = await apiInstance.get(`/store/config`);
      const tempConfig = { ...(result.data as IStoreConfig) };

      setBlockStatus(tempConfig.block_products_active);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getBlockProductsList = async () => {
    setLoading(true);
    try {
      const result = await apiInstance.get(`/block-products`);
      if (result.data !== null) {
        setBlockProducts(result.data);
      }
    } catch (error) {
      setBlockProducts([]);
    }
    setLoading(false);
  };

  const searchProducts = async (page = 1, reset = false) => {
    setLoadingProducts(true);
    try {
      const result = await apiInstance.get(
        `/products?q=${reset ? '' : searchProductQuery}&page=${page}`,
      );
      if (result.data !== null) {
        setProducts(result.data.result);
        setProductsMeta(result.data.meta as IPaginateMeta);
      }
    } catch (error) {
      setProducts([]);
    }
    setLoadingProducts(false);
  };

  const handleChangeProductQuerySearch = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setSearchProductQuery(value);

    // Limpa o timeout anterior, se existir
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Define um novo timeout de 500 milissegundos (ou o valor desejado)
    const newTimeout = setTimeout(() => {
      searchProducts(productsMeta?.page || 1);
    }, 500);

    setSearchTimeout(newTimeout);

    return false;
  };

  const handleOpenSidebarProduct = () => {
    searchProducts();
    setOpenSearchProdut(true);
  };

  const handleAddProductToBlock = async (
    event: ChangeEvent<HTMLInputElement>,
    product: any,
  ) => {
    const { checked } = event.target;

    const lang = product.default_lang || 'pt';

    const result = checked
      ? await apiInstance.post(`/block-products`, {
          product_id: product.id,
          product_name: product.name[lang],
        })
      : await apiInstance.delete(`/block-products/${product.id}`);

    if (result.status === 200 || result.status === 201) {
      if (checked) {
        blockProducts.push({
          product_id: product.id,
          product_name: product.name[lang],
          store_id: -1,
        });
      } else {
        for (let x = 0; x < blockProducts.length; x++) {
          if (blockProducts[x].product_id === product.id) {
            blockProducts.splice(x, 1);
          }
        }
      }

      addToast({
        id: 'submit_add_remove' + new Date().toISOString(),
        type: 'success',
        text: `${t(
          `BlockProductsPage.Toasts.${
            checked ? 'add' : 'remove'
          }_product_success`,
        )}`,
        position: 4,
      });
      setBlockProducts([...blockProducts]);
    } else {
      addToast({
        id: 'submit_add_remove' + new Date().toISOString(),
        type: 'danger',
        text: `${t(
          `BlockProductsPage.Toasts.${
            checked ? 'add' : 'remove'
          }_product_error`,
        )}`,
        position: 4,
      });
      event.target.checked = !checked;
    }
  };

  const handleRemoveProductToBlock = async (
    index: number,
    product_id: number,
  ) => {
    try {
      const result = await apiInstance.delete(`/block-products/${product_id}`);

      if (result.status === 200) {
        addToast({
          id: 'submit_add_remove' + new Date().toISOString(),
          type: 'success',
          text: `${t(`BlockProductsPage.Toasts.remove_product_success`)}`,
          position: 4,
        });
        blockProducts.splice(index, 1);
        setBlockProducts([...blockProducts]);
      }
    } catch (error) {
      addToast({
        id: 'submit_add_remove' + new Date().toISOString(),
        type: 'danger',
        text: `${t(`BlockProductsPage.Toasts.remove_product_error`)}`,
        position: 4,
      });
    }
  };

  const handleToggleBlockStatus = async () => {
    setLoading(true);
    try {
      console.log(blockStatus);
      const result = await apiInstance.post(
        `/block-products/status/${blockStatus ? 'desactive' : 'active'}`,
      );
      if (result.status === 200) {
        setBlockStatus(!blockStatus);
        addToast({
          id: 'submit_start_stop' + new Date().toISOString(),
          type: 'success',
          text: `${t(`BlockProductsPage.Toasts.alter_status_success`)}`,
          position: 4,
        });
      } else {
        addToast({
          id: 'submit_start_stop' + new Date().toISOString(),
          type: 'danger',
          text: `${t(`BlockProductsPage.Toasts.alter_status_error`)}`,
          position: 4,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      <Page>
        <Page.Header title={`${t(`BlockProductsPage.title`)}`} />
        <Page.Body
          marginX="auto"
          width={{
            md: '50%',
            xs: '100%',
          }}
        >
          <Layout columns="1" gap="6">
            <Layout.Section>
              <Box display="grid" gap="6">
                <Card>
                  <Card.Header>
                    <Box display="flex" justifyContent="space-between">
                      <Title as="h4">{`${t('BlockProductsPage.Card.BlockKit.Title')}`}</Title>
                      <Toggle
                        disabled={loading}
                        name="app-status"
                        checked={blockStatus}
                        onClick={handleToggleBlockStatus}
                        label={
                          loading
                            ? `Aguarde...`
                            : `${t('BlockProductsPage.Card.BlockKit.ToagleStatus')}`
                        }
                      />
                    </Box>
                  </Card.Header>
                  <Card.Body>
                    <Text>{`${t(`BlockProductsPage.Card.BlockKit.Description`)}`}</Text>
                  </Card.Body>
                </Card>

                <Card padding="none">
                  <Card.Header padding="base">
                    <Box display="flex" justifyContent="space-between">
                      <Title as="h4">{`${t('BlockProductsPage.Card.BlockProducts.Title')}`}</Title>
                      <Link
                        as="button"
                        appearance="primary"
                        onClick={handleOpenSidebarProduct}
                      >
                        <Icon
                          source={<PlusCircleIcon />}
                          color="currentColor"
                        />
                        {t(`BlockProductsPage.Card.BlockProducts.CtaAdd`)}
                      </Link>
                    </Box>
                  </Card.Header>
                  <Card.Body padding="none">
                    <Box paddingX="4" paddingBottom="4">
                      <Text>{`${t('BlockProductsPage.Card.BlockProducts.Description')}`}</Text>
                    </Box>
                    <DataList>
                      {loading || blockProducts.length ? (
                        loading ? (
                          <DataList.Row>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              gap="4"
                            >
                              <Text.Skeleton width="30%" />
                              <IconButton.Skeleton />
                            </Box>
                          </DataList.Row>
                        ) : (
                          blockProducts.map((item, i) => (
                            <DataList.Row key={item._id}>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                gap="4"
                              >
                                <Box display="grid" gap="1">
                                  <Text fontWeight="medium">
                                    {item.product_name}
                                  </Text>
                                  {item.createdAt ? (
                                    <Text fontSize="caption">
                                      Bloqueado em{' '}
                                      {formatDate(item.createdAt, 'long')}
                                    </Text>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                                <IconButton
                                  source={<TrashIcon size="small" />}
                                  onClick={() => {
                                    handleRemoveProductToBlock(
                                      i,
                                      item.product_id,
                                    );
                                  }}
                                />
                              </Box>
                            </DataList.Row>
                          ))
                        )
                      ) : (
                        <DataList.Row>
                          <EmptyMessage
                            title={t(
                              `BlockProductsPage.Card.BlockProducts.EmptyMessage.title`,
                            )}
                            text={`${t(`BlockProductsPage.Card.BlockProducts.EmptyMessage.text`)}`}
                            icon={<SearchIcon size={32} />}
                            actions={
                              <Button
                                appearance="neutral"
                                onClick={handleOpenSidebarProduct}
                              >
                                <Icon
                                  source={<PlusCircleIcon />}
                                  color="currentColor"
                                />
                                {t(
                                  `BlockProductsPage.Card.BlockProducts.CtaAdd`,
                                )}
                              </Button>
                            }
                          />
                        </DataList.Row>
                      )}
                    </DataList>
                  </Card.Body>
                </Card>
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>

      {/* BUSCA PRODUTOS */}
      <Sidebar
        padding="none"
        open={openSearchProdut}
        onRemove={() => setOpenSearchProdut(false)}
      >
        <Sidebar.Header padding="base">
          <Box display="grid" gap="2">
            <Title as="h4">
              {t(`BlockProductsPage.sidebar.product.title`)}
            </Title>
            <Input.Search
              placeholder={`${t(
                `BlockProductsPage.sidebar.product.search_placeholder`,
              )}`}
              onChange={handleChangeProductQuerySearch}
              value={searchProductQuery}
            />
          </Box>
        </Sidebar.Header>
        <Sidebar.Body>
          {loadingProducts ? (
            <InteractiveList>
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
            </InteractiveList>
          ) : products.length ? (
            <>
              <InteractiveList>
                {products.map((product: any, index: number) => (
                  <InteractiveList.CheckboxItem
                    key={index}
                    title={''}
                    showTitle={false}
                    checkbox={{
                      name: 'checkbox-element-' + index,
                      checked:
                        blockProducts.length &&
                        blockProducts.find(
                          (item) => item.product_id === product.id,
                        )
                          ? true
                          : false,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      onChange: (event: ChangeEvent<HTMLInputElement>) =>
                        handleAddProductToBlock(event, product),
                    }}
                  >
                    <Box display="flex" alignItems="center" gap="3">
                      {product.images.length ? (
                        <Thumbnail
                          src={product.images[0].src}
                          alt={product.name[product.default_lang]}
                          width="40px"
                        />
                      ) : (
                        <Thumbnail
                          alt={product.name[product.default_lang]}
                          width="40px"
                        />
                      )}
                      <Text>{product.name[product.default_lang]}</Text>
                    </Box>
                  </InteractiveList.CheckboxItem>
                ))}
              </InteractiveList>
              {productsMeta && productsMeta.pages > 1 ? (
                <Pagination
                  activePage={productsMeta.page}
                  onPageChange={(page) => searchProducts(page)}
                  pageCount={productsMeta.pages}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <EmptyMessage
              title={t('BlockProductsPage.sidebar.product.empty_title')}
              text={`${t('BlockProductsPage.sidebar.product.empty_text')}`}
              icon={<SearchIcon size={32} />}
              actions={
                <Button
                  appearance="primary"
                  onClick={() => {
                    setSearchProductQuery('');
                    searchProducts(1, true);
                  }}
                >
                  <Icon color="currentColor" source={<UndoIcon />} />
                  {t('BlockProductsPage.sidebar.product.empty_cta')}
                </Button>
              }
            />
          )}
        </Sidebar.Body>
        <Sidebar.Footer padding="base">
          <Box display="flex" justifyContent="flex-end">
            <Button
              appearance="primary"
              onClick={() => setOpenSearchProdut(false)}
            >
              {t('general.save')}
            </Button>
          </Box>
        </Sidebar.Footer>
      </Sidebar>
    </>
  );
};

export default BlockProductsPage;
