import React, { useEffect, useState } from 'react';
import {
  BuyTogetherDisplayModeOptions,
  IStoreConfig,
} from '../../lib/interfaces/config.interfaces';
import { Box, Spinner, Thumbnail, Title } from '@nimbus-ds/components';

interface BuyTogetherModelsProps {
  config: IStoreConfig | null;
}

const BuyTogetherModels: React.FC<BuyTogetherModelsProps> = ({
  config,
}: BuyTogetherModelsProps) => {
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    const link = document.createElement('link');
    link.href =
      'https://cdn.huapps.com.br/frontstore-apps/nuvemshop/compre-junto-pro/app.min.css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    link.onload = () => {
      setShowPreview(true);
    };

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      :root {
        --huapps-compre-junto-pro-primary_color: ${
          config?.primary_color || '#358ebb'
        };
        --huapps-compre-junto-pro-secondary_color: ${
          config?.secondary_color || '#363636'
        };
        --huapps-compre-junto-pro-success_color: ${
          config?.success_color || '#25d058'
        };
        --huapps-compre-junto-pro-title_color: ${
          config?.title_color || '#383838'
        };
        --huapps-compre-junto-pro-text_color: ${
          config?.text_color || '#404040'
        };
        --huapps-compre-junto-pro-border_color: ${
          config?.border_color || '#f5f5f5'
        };
      }
    `;
    document.head.appendChild(styleElement);
  }, [config]);

  const ProductItem: React.FC = () => {
    switch (config?.buy_together_display_mode) {
      case BuyTogetherDisplayModeOptions.GRID:
        return (
          <div className="huapps-buy-together-item">
            <div className="huapps-buy-together-item-border">
              <div className="huapps-buy-together-item-enabled-option">
                <label className="huapps-buy-together-item-choice-checkbox-label">
                  <input
                    type="checkbox"
                    checked={true}
                    className="js-huapps-buy-together-item-choice-checkbox"
                  />
                  <div className="huapps-buy-together-item-enabled-option-label">
                    <span>✔</span> {config.buy_together_enabled_option_text}
                  </div>
                  <div className="huapps-buy-together-item-disabled-option-label">
                    <span>×</span> {config.buy_together_disabled_option_text}
                  </div>
                </label>
              </div>
              <div className="huapps-buy-together-item-images">
                <Thumbnail alt="my-image" width="100%" />
              </div>
              <div className="huapps-buy-together-item-detail">
                <div className="huapps-buy-together-item-name">
                  <a href="#">Item 1</a>
                </div>
                <div className="huapps-buy-together-item-prices">
                  <div className="huapps-buy-together-item-prices-de">
                    <div className="js-huapps-buy-together-item-get-prices-de">
                      R$ 199,90
                    </div>
                  </div>
                  <div className="huapps-buy-together-item-prices-por">
                    <div className="js-huapps-buy-together-item-get-prices-por">
                      R$ 99,00
                    </div>
                  </div>
                </div>
                <div className="huapps-buy-together-item-discount-tag">
                  <span>50% off no kit</span>
                </div>
              </div>
            </div>
          </div>
        );
      case BuyTogetherDisplayModeOptions.LIST:
        return (
          <div className="huapps-buy-together-item">
            <div className="huapps-buy-together-item-border">
              <div className="huapps-buy-together-item-enabled-option">
                <label className="huapps-buy-together-item-choice-checkbox-label">
                  <input
                    type="checkbox"
                    checked={true}
                    className="js-huapps-buy-together-item-choice-checkbox"
                  />
                  <div className="huapps-buy-together-item-enabled-option-label">
                    <span>✔</span> {config.buy_together_enabled_option_text}
                  </div>
                  <div className="huapps-buy-together-item-disabled-option-label">
                    <span>×</span> {config.buy_together_disabled_option_text}
                  </div>
                </label>
              </div>
              <div className="huapps-buy-together-item-images">
                <Thumbnail alt="my-image" width="64px" />
              </div>
              <div className="huapps-buy-together-item-detail">
                <div className="huapps-buy-together-item-name">
                  <a href="#">Item 1</a>
                </div>
                <div className="huapps-buy-together-item-prices">
                  <div className="huapps-buy-together-item-prices-de">
                    <div className="js-huapps-buy-together-item-get-prices-de">
                      R$ 199,90
                    </div>
                  </div>
                  <div className="huapps-buy-together-item-prices-por">
                    <div className="js-huapps-buy-together-item-get-prices-por">
                      R$ 99,00
                    </div>
                  </div>
                  <div className="huapps-buy-together-item-discount-tag">
                    <span>50% off no kit</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case BuyTogetherDisplayModeOptions.MINIMAL:
        return (
          <div className="huapps-buy-together-item">
            <div className="huapps-buy-together-item-border">
              <div className="huapps-buy-together-item-enabled-option">
                <label className="huapps-buy-together-item-choice-checkbox-label">
                  <input
                    type="checkbox"
                    checked={true}
                    className="js-huapps-buy-together-item-choice-checkbox"
                  />
                  <div className="huapps-buy-together-item-enabled-option-label">
                    <span>✔</span> {config.buy_together_enabled_option_text}
                  </div>
                  <div className="huapps-buy-together-item-disabled-option-label">
                    <span>×</span> {config.buy_together_disabled_option_text}
                  </div>
                </label>
              </div>
              <div className="huapps-buy-together-item-images">
                <Thumbnail alt="my-image" width="48px" />
              </div>
              <div className="huapps-buy-together-item-detail">
                <div className="huapps-buy-together-item-name">
                  <a href="#">Item 1</a>
                </div>
              </div>
              <div className="huapps-buy-together-item-variations"></div>
              <div className="huapps-buy-together-item-prices"></div>
              <div className="huapps-buy-together-item-discount-tag">
                <span>50% off no kit</span>
              </div>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  if (!showPreview) {
    return (
      <Box display="flex" justifyContent="center" gap="2">
        <Spinner size="medium" />
      </Box>
    );
  }
  if (config) {
    return (
      <Box
        borderColor="neutral-surfaceHighlight"
        borderWidth="1"
        borderStyle="dashed"
        padding="4"
        borderRadius="2"
        marginY="2"
      >
        <div id="preview">
          <div className="huapps-buy-together-groups js-huapps-buy-together-content huapps-buy-together-custom-colors">
            <div className="huapps-buy-together-groups-title">
              <Title as="h3">{config.buy_together_title}</Title>
            </div>
            <div className="huapps-buy-together-groups-items">
              <div className="js-huapps-buy-together-groups-items">
                <div
                  className={`huapps-buy-together-group-${config?.buy_together_display_mode} huapps-buy-together-border js-huapps-buy-together-group`}
                >
                  <div className="huapps-buy-together-items">
                    <div className="huapps-buy-together-items-content">
                      <div className="js-huapps-buy-together-get-product js-huapps-product-is-ok">
                        <ProductItem />
                      </div>
                      <div className="huapps-buy-together-icon-plus">+</div>
                      <div className="js-huapps-buy-together-get-product js-huapps-product-is-ok">
                        <ProductItem />
                      </div>
                    </div>
                  </div>
                  <div className="huapps-buy-together-icon-equals">=</div>
                  <div className="huapps-buy-together-actions">
                    <div className="huapps-buy-together-actions-border">
                      <p
                        className="huapps-buy-together-actions-message"
                        dangerouslySetInnerHTML={{
                          __html: config.buy_together_message_discount
                            .replaceAll('{qtd}', '<strong>2</strong>')
                            .replaceAll(
                              '{valor}',
                              '<strong>R$ 100,00</strong>',
                            ),
                        }}
                      />
                      <div className="huapps-buy-together-actions-prices">
                        <div className="huapps-buy-together-actions-prices-de js-huapps-buy-together-get-total-price-de">
                          R$ 399,80
                        </div>
                        <div className="huapps-buy-together-actions-prices-por js-huapps-buy-together-get-total-price-por">
                          R$ 198,00
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary huapps-buy-together-actions-cta js-huapps-buy-together-add-all-to-cart"
                      >
                        {config.buy_together_cta_text}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    );
  } else {
    return <></>;
  }
};

export default BuyTogetherModels;
