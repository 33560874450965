import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import nexo from './nexoClient';
import {
  syncPathname,
  ACTION_NAVIGATE_SYNC,
  NavigateSyncResponse,
} from '@tiendanube/nexo';

function NexoSyncRoute({ children }: { children: JSX.Element }) {
  const { pathname } = useLocation();
  const navigate = useNavigate(); // useNavigate retorna uma função de navegação

  useEffect(() => {
    syncPathname(nexo, pathname);
  }, [pathname]);

  useEffect(() => {
    const unsuscribe = nexo.suscribe(
      ACTION_NAVIGATE_SYNC,
      ({ path, replace }: NavigateSyncResponse) => {
        navigate(path, { replace }); // Use navigate com a opção replace
      },
    );

    return unsuscribe;
  }, [navigate]);

  return children;
}

export default NexoSyncRoute;
