import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Icon,
  Title,
  Text,
  Tag,
  Toggle,
  IconButton,
  useToast,
  Pagination,
  Thumbnail,
  Tooltip,
  Button,
  Input,
  Select,
  Modal,
  Spinner,
  Link,
} from '@nimbus-ds/components';
import {
  AppsIcon,
  BoxPackedIcon,
  DiscountCircleIcon,
  EditIcon,
  MarketingIcon,
  PlusCircleIcon,
  RedoIcon,
  SlidersIcon,
  TrashIcon,
} from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { IGroup } from '../../lib/interfaces/groups.interfaces';
import { DataList, EmptyMessage, SideModal } from '@nimbus-ds/patterns';
import apiInstance from '../../utils/apiUtils';
import { IPaginateMeta } from '../../lib/interfaces/paginate.interfaces';
import { useNavigate } from 'react-router-dom';
import DashboardItems from './DashboardItems';
import emptyPromotions from '../../lib/assets/empty-promotions.png';

const GroupsListTable: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { addToast } = useToast();

  type ISearchType = '' | 'is_but_together' | 'is_kit';
  type ISearchStatus = '' | 'active' | 'disabled';

  const [openFilterSideModal, setOpenFilterSideModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [groupsMeta, setGroupsMeta] = useState<IPaginateMeta>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchByType, setSearchByType] = useState<ISearchType>('');
  const [searchByStatus, setSearchByStatus] = useState<ISearchStatus>('');
  const [deleteGroupID, setDeleteGroupID] = useState('');
  const [haveGroup, setHaveGroup] = useState(false);

  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  const loadGroups = async (
    page = groupsMeta?.page || 1,
    searchQueryGroups = '',
  ) => {
    setLoading(true);
    try {
      const params: string[] = [];

      if (searchByType === 'is_but_together') {
        params.push(`show_buy_together=true`);
      } else if (searchByType === 'is_kit') {
        params.push(`product_kit=true`);
      }

      if (searchByStatus === 'active') {
        params.push(`active=true`);
      } else if (searchByStatus === 'disabled') {
        params.push(`active=false`);
      }

      setSearchQuery(searchQueryGroups);

      const result = await apiInstance.get(
        `/group?q=${searchQueryGroups}&page=${page}${
          params.length ? `&${params.join('&')}` : ``
        }`,
      );
      if (result.data !== null) {
        setGroups(result.data.result as IGroup[]);
        setGroupsMeta(result.data.meta as IPaginateMeta);

        if (!haveGroup && groups.length) {
          setHaveGroup(true);
        }
      }
    } catch (error) {
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: t('MainPage.GroupsListTable.toasts.loadGroups.error'),
        position: 4,
      });
    }
    setLoading(false);
  };

  const resetSearch = () => {
    setSearchByType('');
    setSearchByStatus('');

    // Limpa o timeout anterior, se existir
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Define um novo timeout de 500 milissegundos (ou o valor desejado)
    const newTimeout = setTimeout(() => {
      loadGroups(groupsMeta?.page || 1);
    }, 500);

    setSearchTimeout(newTimeout);

    return false;
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearchQuery(value);

    // Limpa o timeout anterior, se existir
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Define um novo timeout de 500 milissegundos (ou o valor desejado)
    const newTimeout = setTimeout(() => {
      loadGroups(groupsMeta?.page || 1, value);
    }, 500);

    setSearchTimeout(newTimeout);

    return false;
  };

  const closeDeleteModal = () => {
    setLoadingDelete(false);
    setOpenDeleteModal(false);
    setDeleteGroupID('');
  };

  const handleOpenDeleteModal = (groupID: string) => {
    setDeleteGroupID(groupID);
    setOpenDeleteModal(true);
  };
  const handleRemoveGroup = async () => {
    try {
      setLoadingDelete(true);
      await apiInstance.delete(`/group/${deleteGroupID}`);
      addToast({
        id: 'delete' + new Date().toISOString(),
        type: 'success',
        text: t('MainPage.GroupsListTable.toasts.deleteGroup.success'),
        position: 4,
      });
      loadGroups();
    } catch (error) {
      addToast({
        id: 'delete' + new Date().toISOString(),
        type: 'danger',
        text: t('MainPage.GroupsListTable.toasts.deleteGroup.error'),
        position: 4,
      });
    }
    closeDeleteModal();
  };

  const handleStartStopGroup = async (group_id: string, i: number) => {
    try {
      const result = await apiInstance.post(`/group/${group_id}/change-status`);
      if (result) {
        addToast({
          id: 'error_post_status' + new Date().toISOString(),
          type: 'success',
          text: t('MainPage.GroupsListTable.toasts.change_status.success'),
          position: 4,
        });
      }
    } catch (error) {
      groups[i].active = !groups[i].active;
      setGroups([...groups]);
      addToast({
        id: 'error_post_status' + new Date().toISOString(),
        type: 'danger',
        text: t('MainPage.GroupsListTable.toasts.change_status.error'),
        position: 4,
      });
    }
  };

  const handleSyncGroups = async () => {
    try {
      const result = await apiInstance.post(`/groups/sync`);
      if (result) {
        addToast({
          id: 'sync_groups' + new Date().toISOString(),
          type: 'success',
          text: t('MainPage.GroupsListTable.toasts.sync_groups.success'),
          position: 4,
        });
      }
    } catch (error) {
      addToast({
        id: 'sync_groups' + new Date().toISOString(),
        type: 'danger',
        text: t('MainPage.GroupsListTable.toasts.sync_groups.error'),
        position: 4,
      });
    }
  };

  useEffect(() => {
    loadGroups();
  }, [searchByStatus, searchByType]);

  return (
    <>
      {groups.length || haveGroup ? (
        <>
          <DashboardItems />
          <Box display="grid" gap="2">
            <Box
              display="flex"
              gap="2"
              justifyContent="space-between"
              alignItems="center"
            >
              <Title as="h2">{t('MainPage.GroupsListTable.title')}</Title>
            </Box>

            <Box display="flex" flexDirection="column" gap="2">
              <Box display="flex" gap="1">
                <Input.Search
                  placeholder={`${t(
                    'MainPage.GroupsListTable.list.search.query_placeholder',
                  )}`}
                  name="search-query"
                  onChange={handleChangeSearch}
                  value={searchQuery}
                />
                <Button
                  onClick={() => {
                    setOpenFilterSideModal(true);
                  }}
                >
                  <Icon color="currentColor" source={<SlidersIcon />} />
                </Button>
              </Box>
            </Box>

            <Box display="grid" gap="4" boxSizing="border-box" marginTop="2">
              <Box display="grid" gap="4">
                {loading ? (
                  <DataList>
                    {Array.from({ length: 3 }, (_, i) => (
                      <DataList.Row key={i} gap="1">
                        <Box display="flex" justifyContent="space-between">
                          <Text.Skeleton width="13rem" />
                          <Toggle.Skeleton />
                        </Box>
                        <Box
                          display="flex"
                          gap="3"
                          alignItems="center"
                          flexWrap="wrap"
                        >
                          {Array.from({ length: 3 }, (_, ii) => (
                            <Box
                              key={ii}
                              display="flex"
                              alignItems="center"
                              flexWrap="wrap"
                            >
                              {ii > 0 ? (
                                <Text fontWeight="bold">+</Text>
                              ) : (
                                <></>
                              )}
                              <Thumbnail.Skeleton key={ii} width="60px" />
                            </Box>
                          ))}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box display="flex" flexWrap="wrap" gap="2">
                            <Tag.Skeleton width="13rem" />
                            <Tag.Skeleton width="13rem" />
                            <Tag.Skeleton width="13rem" />
                          </Box>
                          <Box display="flex" gap="2">
                            <IconButton.Skeleton height="36px" width="36px" />
                            <IconButton.Skeleton height="36px" width="36px" />
                          </Box>
                        </Box>
                      </DataList.Row>
                    ))}
                  </DataList>
                ) : groups.length ? (
                  <>
                    <DataList>
                      {groups.map((group, i) => (
                        <DataList.Row key={i} gap="1">
                          <Box display="flex" justifyContent="space-between">
                            <Text
                              fontSize="highlight"
                              fontWeight="medium"
                              color="primary-interactive"
                            >
                              {group.name}
                            </Text>
                            <Box display="flex" gap="3">
                              <Toggle
                                name={`is_cart_chronometer_${group._id}`}
                                active={group.active}
                                label="Ativo"
                                onChange={() =>
                                  handleStartStopGroup(group._id!, i)
                                }
                              />
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            gap="3"
                            alignItems="center"
                            flexWrap="wrap"
                          >
                            {group.products.map((product, productIndex) => (
                              <Box
                                key={productIndex}
                                display="flex"
                                alignItems="center"
                                flexWrap="wrap"
                              >
                                {productIndex > 0 ? (
                                  <Text fontWeight="bold">+</Text>
                                ) : (
                                  <></>
                                )}
                                <Tooltip
                                  content={product.name}
                                  key={productIndex}
                                >
                                  {product.default_image ? (
                                    <Thumbnail
                                      src={product.default_image}
                                      alt={product.name}
                                      width="60px"
                                    />
                                  ) : (
                                    <Thumbnail
                                      alt={product.name}
                                      width="60px"
                                    />
                                  )}
                                </Tooltip>
                              </Box>
                            ))}
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box display="flex" flexWrap="wrap" gap="2">
                              {group.product_kit ? (
                                <Tag appearance="primary">
                                  <Icon
                                    color="currentColor"
                                    source={<BoxPackedIcon />}
                                  />
                                  {t('MainPage.GroupsListTable.list.is_kit')}
                                </Tag>
                              ) : (
                                <></>
                              )}
                              {group.show_buy_together ? (
                                <Tag appearance="primary">
                                  <Icon
                                    color="currentColor"
                                    source={<AppsIcon />}
                                  />
                                  {t(
                                    'MainPage.GroupsListTable.list.is_buy_together',
                                  )}
                                </Tag>
                              ) : (
                                <></>
                              )}
                              {group.apply_discount ? (
                                <Tag appearance="success">
                                  <Icon
                                    color="currentColor"
                                    source={<DiscountCircleIcon />}
                                  />
                                  {t(
                                    'MainPage.GroupsListTable.list.is_apply_discount',
                                  )}
                                </Tag>
                              ) : (
                                <></>
                              )}
                            </Box>
                            <Box display="flex" gap="2">
                              <IconButton
                                onClick={() => {
                                  navigate(
                                    `/statistics/groups/${group._id!}/orders`,
                                  );
                                }}
                                source={<MarketingIcon size="small" />}
                                size="36px"
                              />
                              <IconButton
                                onClick={() => {
                                  navigate(`/group/${group._id!}`);
                                }}
                                source={<EditIcon size="small" />}
                                size="36px"
                              />
                              <IconButton
                                onClick={() => {
                                  handleOpenDeleteModal(group._id!);
                                }}
                                source={<TrashIcon size="small" />}
                                size="36px"
                              />
                            </Box>
                          </Box>
                        </DataList.Row>
                      ))}
                    </DataList>
                    <Box display="flex" justifyContent="space-between">
                      {groupsMeta && groupsMeta.pages > 1 ? (
                        <Pagination
                          activePage={groupsMeta?.page || 1}
                          onPageChange={loadGroups}
                          pageCount={groupsMeta?.pages || 0}
                        />
                      ) : (
                        <Box></Box>
                      )}
                      <Button onClick={handleSyncGroups} appearance="neutral">
                        Sincronizar Fotos
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Box paddingTop="5" paddingBottom="5">
                    <EmptyMessage
                      title={t('MainPage.GroupsListTable.empty_message.title')}
                      actions={
                        <Link
                          as="button"
                          appearance="primary"
                          onClick={resetSearch}
                        >
                          <Icon color="currentColor" source={<RedoIcon />} />
                          Limpar filtro
                        </Link>
                      }
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Modal open={openDeleteModal} onDismiss={() => closeDeleteModal()}>
              <Modal.Header
                title={`${t(
                  'MainPage.GroupsListTable.modals.removeGroup.title',
                )}`}
              />
              <Modal.Body>
                <Text>
                  {t('MainPage.GroupsListTable.modals.removeGroup.text')}
                </Text>
              </Modal.Body>
              <Modal.Footer>
                <Box>
                  <Button
                    disabled={loadingDelete}
                    onClick={handleRemoveGroup}
                    appearance="danger"
                  >
                    {loadingDelete ? (
                      <Spinner color="currentColor" size="small" />
                    ) : (
                      ''
                    )}
                    {t('MainPage.GroupsListTable.modals.removeGroup.cta')}
                  </Button>
                </Box>
              </Modal.Footer>
            </Modal>

            <SideModal
              open={openFilterSideModal}
              maxWidth={{ xs: '100%', md: '340px' }}
              title="Filtrar por"
              headerIcon={
                <Icon color="primary-textHigh" source={<SlidersIcon />} />
              }
              onRemove={() => setOpenFilterSideModal(false)}
              padding="base"
              paddingHeader="none"
              paddingBody="none"
              paddingFooter="none"
            >
              <Box display="grid" gap="6">
                <Box display="grid" gap="2">
                  <Text as="span">
                    {t('MainPage.GroupsListTable.list.search.group_type.label')}
                  </Text>
                  <Select
                    id="search-type"
                    name="search-type"
                    onChange={(e) => {
                      setSearchByType(e.target.value as ISearchType);
                      setOpenFilterSideModal(false);
                      loadGroups(groupsMeta?.page || 1);
                    }}
                    value={searchByType}
                  >
                    <Select.Option
                      label={t(
                        'MainPage.GroupsListTable.list.search.group_type.option_1',
                      )}
                      value=""
                    />
                    <Select.Option
                      label={t(
                        'MainPage.GroupsListTable.list.search.group_type.option_2',
                      )}
                      value="is_but_together"
                    />
                    <Select.Option
                      label={t(
                        'MainPage.GroupsListTable.list.search.group_type.option_3',
                      )}
                      value="is_kit"
                    />
                  </Select>
                </Box>
                <Box display="grid" gap="2">
                  <Text as="span">
                    {t('MainPage.GroupsListTable.list.search.status.label')}
                  </Text>
                  <Select
                    id="search-status"
                    name="search-status"
                    onChange={(e) => {
                      setSearchByStatus(e.target.value as ISearchStatus);
                      setOpenFilterSideModal(false);
                      loadGroups(groupsMeta?.page || 1);
                    }}
                  >
                    <Select.Option
                      label={t(
                        'MainPage.GroupsListTable.list.search.status.option_1',
                      )}
                      value=""
                    />
                    <Select.Option
                      label={t(
                        'MainPage.GroupsListTable.list.search.status.option_2',
                      )}
                      value="active"
                    />
                    <Select.Option
                      label={t(
                        'MainPage.GroupsListTable.list.search.status.option_3',
                      )}
                      value="disabled"
                    />
                  </Select>
                </Box>
              </Box>
            </SideModal>
          </Box>
        </>
      ) : (
        <EmptyMessage
          title={t('MainPage.GroupsListTable.empty_message.title')}
          text={`${t('MainPage.GroupsListTable.empty_message.text')}`}
          actions={
            <Link
              as="button"
              appearance="primary"
              onClick={() => navigate('/group')}
            >
              <Icon color="currentColor" source={<PlusCircleIcon />} />
              {t('MainPage.GroupsListTable.empty_message.cta')}
            </Link>
          }
          illustration={
            <img
              width={600}
              alt={`${t('MainPage.GroupsListTable.empty_message.title')}`}
              src={emptyPromotions}
            />
          }
        />
      )}
    </>
  );
};

export default GroupsListTable;
