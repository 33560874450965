import React from 'react';
import { ThemeProvider } from '@nimbus-ds/styles';
import NavButtons from '../NavButtons';
import { Box } from '@nimbus-ds/components';

const BaseLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // The admin currently doesn't support dark mode. When it does, replace `base` value with `currentTheme` to provide dark mode support.
  return (
    <ThemeProvider theme="base">
      <Box paddingBottom="18">
        {children}
        <NavButtons />
      </Box>
    </ThemeProvider>
  );
};

export default BaseLayout;
