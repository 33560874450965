import React, { useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import {
  Alert,
  Box,
  Label,
  Text,
  Textarea,
  useToast,
} from '@nimbus-ds/components';
import { Layout, Page } from '@nimbus-ds/patterns';
import { navigateHeader } from '@tiendanube/nexo';
import { useTranslation } from 'react-i18next';
import apiInstance from '../../utils/apiUtils';
import { useParams } from 'react-router-dom';

const StatisticsOrdersByGroupPage: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [ordersInfo, setOrdersInfo] = useState<{ [key: string]: any }>({});
  const [ordersDetails, setOrdersDetails] = useState<{ [key: string]: any }[]>(
    [],
  );
  const [emailsText, setEmailsText] = useState<string>('');

  const { group_id } = useParams<{ group_id: string }>();

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });
    getOrdersByGroup();
  }, []);

  const getOrdersByGroup = async () => {
    setLoading(true);
    try {
      const result = await apiInstance.get(
        `/statistics/groups/${group_id}/orders`,
      );
      setOrdersInfo({ ...(result.data as any) });
      setOrdersDetails(result.data.orders_details);

      if (result.data.orders_details && result.data.orders_details.length) {
        const emails: string[] = [];
        for (let x = 0; x < result.data.orders_details.length; x++) {
          emails.push(result.data.orders_details[x].contact_email);
        }
        setEmailsText(emails.join(','));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      <Page>
        <Page.Header
          title={`${t(`StatisticsOrdersByGroupPage.title`)}`}
          subtitle={`${t(`StatisticsOrdersByGroupPage.subtitle`)}`}
        />
        <Page.Body marginX="auto">
          <Layout columns="1" gap="6">
            <Layout.Section>
              <Box display="grid" gap="6" width="100%">
                <Alert appearance="danger">EM DESENVOLDIMENTO</Alert>
                {ordersDetails && ordersDetails.length ? (
                  ordersDetails.map((order, index) => (
                    <Text key={index}>{order.contact_email}</Text>
                  ))
                ) : (
                  <></>
                )}
                <Box display="grid" gap="2">
                  <Label>E-mails separados por virgula (,)</Label>
                  <Textarea id="emailsText" value={emailsText} />
                </Box>
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default StatisticsOrdersByGroupPage;
