import React, { useEffect, useState } from 'react';
import { Box, Card, Icon, Skeleton, Text, Title } from '@nimbus-ds/components';
import {
  BoxPackedIcon,
  CheckCircleIcon,
  ShoppingCartIcon,
} from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import apiInstance from '../../utils/apiUtils';

const DashboardItems: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [kitsCount, setKitsCount] = useState(0);
  const [enabledKitsCount, setEnabledKitsCount] = useState(0);
  const [shoppingKitsCount, setShoppingKitsCount] = useState(0);

  const getStatistics = async () => {
    setLoading(true);
    try {
      const result = await apiInstance.get(`/statistics`);
      if (result.data !== null) {
        setKitsCount((result.data.totals_kits as number) || 0);
        setEnabledKitsCount((result.data.active_kits as number) || 0);
        setShoppingKitsCount((result.data.sell_kits as number) || 0);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getStatistics();
  }, []);

  return (
    <Box display="grid" gap="2">
      <Title as="h2">{t('MainPage.DashboardItems.title')}</Title>
      <Box display="flex" gap="4" boxSizing="border-box">
        {loading ? (
          Array.from({ length: 3 }, (_, index) => (
            <Box width={`${100 / 3}%`} key={index} boxSizing="border-box">
              <Card>
                <Card.Body>
                  <Box display="flex" alignItems="center" gap="2">
                    <Icon.Skeleton height="2rem" width="40px" />
                    <Skeleton height="2rem" width="80%" borderRadius="3" />
                  </Box>
                </Card.Body>
              </Card>
            </Box>
          ))
        ) : (
          <>
            <Box width="33.333%" boxSizing="border-box">
              <Card>
                <Card.Body>
                  <Box display="flex" alignItems="center" gap="2">
                    <Icon
                      source={<BoxPackedIcon size="large" />}
                      color="neutral-interactive"
                    />
                    {kitsCount === 0 ? (
                      <Text as="p" fontSize="highlight">
                        {t('MainPage.DashboardItems.items.kits_count.no_kit')}
                      </Text>
                    ) : (
                      <>
                        <Text as="p" fontSize="highlight" fontWeight="bold">
                          {kitsCount}
                        </Text>
                        <Text as="p" fontSize="highlight">
                          {kitsCount === 1
                            ? t(
                                'MainPage.DashboardItems.items.kits_count.one_kit',
                              )
                            : t(
                                'MainPage.DashboardItems.items.kits_count.more_kits',
                              )}
                        </Text>
                      </>
                    )}
                  </Box>
                </Card.Body>
              </Card>
            </Box>
            <Box width="33.333%" boxSizing="border-box">
              <Card>
                <Card.Body>
                  <Box display="flex" alignItems="center" gap="2">
                    <Icon
                      source={<CheckCircleIcon size="large" />}
                      color="primary-interactive"
                    />
                    {enabledKitsCount === 0 ? (
                      <Text as="p" fontSize="highlight">
                        {t(
                          'MainPage.DashboardItems.items.enabled_kits_count.no_kit',
                        )}
                      </Text>
                    ) : (
                      <>
                        <Text as="p" fontSize="highlight" fontWeight="bold">
                          {enabledKitsCount}
                        </Text>
                        <Text as="p" fontSize="highlight">
                          {enabledKitsCount === 1
                            ? t(
                                'MainPage.DashboardItems.items.enabled_kits_count.one_kit',
                              )
                            : t(
                                'MainPage.DashboardItems.items.enabled_kits_count.more_kits',
                              )}
                        </Text>
                      </>
                    )}
                  </Box>
                </Card.Body>
              </Card>
            </Box>
            <Box width="33.333%" boxSizing="border-box">
              <Card>
                <Card.Body>
                  <Box display="flex" alignItems="center" gap="2">
                    <Icon
                      source={<ShoppingCartIcon size="large" />}
                      color="success-interactive"
                    />
                    {shoppingKitsCount === 0 ? (
                      <Text as="p" fontSize="highlight">
                        {t(
                          'MainPage.DashboardItems.items.shopping_kits_count.no_kit',
                        )}
                      </Text>
                    ) : (
                      <>
                        <Text as="p" fontSize="highlight" fontWeight="bold">
                          {shoppingKitsCount}
                        </Text>
                        <Text as="p" fontSize="highlight">
                          {shoppingKitsCount === 1
                            ? t(
                                'MainPage.DashboardItems.items.shopping_kits_count.one_kit',
                              )
                            : t(
                                'MainPage.DashboardItems.items.shopping_kits_count.more_kits',
                              )}
                        </Text>
                      </>
                    )}
                  </Box>
                </Card.Body>
              </Card>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DashboardItems;
